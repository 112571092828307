<template>
    <div>
      <Header/>
      <mdb-parallax style="margin-top: -13px"
          src="/img/bgs/bg9.jpg"
          height="full"
          factor="1"
          >
          <mdb-mask overlay="blue-slight" flexCenter>
              <mdb-row class="mb-5 pb-5 text-center white-text">
              <mdb-col md="12" xl="12" class="mx-auto">
                  <h1 class="display-4 font-weight-bold animated fadeInDown">Galería</h1>
              </mdb-col>
              </mdb-row>
          </mdb-mask>
      </mdb-parallax>

    <mdb-container class="py-5 my-5">
      <mdb-row class="mdb-lightbox no-margin">
        <mdb-col md="4" @click.native="showLightbox(0)">
          <figure>
            <img
              src="/img/gallery/11.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(1)">
          <figure>
            <img
              src="/img/gallery/12.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(2)">
          <figure>
            <img
              src="/img/gallery/13.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(3)">
          <figure>
            <img
              src="/img/gallery/14.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(4)">
          <figure>
            <img
              src="/img/gallery/15.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(5)">
          <figure>
            <img
              src="/img/gallery/16.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(6)">
          <figure>
            <img
              src="/img/gallery/17.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(7)">
          <figure>
            <img
              src="/img/gallery/18.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(8)">
          <figure>
            <img
              src="/img/gallery/19.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(9)">
          <figure>
            <img
              src="/img/gallery/20.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(10)">
          <figure>
            <img
              src="/img/gallery/21.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(11)">
          <figure>
            <img
              src="/img/gallery/22.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(12)">
          <figure>
            <img
              src="/img/gallery/23.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(13)">
          <figure>
            <img
              src="/img/gallery/24.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(14)">
          <figure>
            <img
              src="/img/gallery/25.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(15)">
          <figure>
            <img
              src="/img/gallery/26.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(16)">
          <figure>
            <img
              src="/img/gallery/27.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(17)">
          <figure>
            <img
              src="/img/gallery/28.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(18)">
          <figure>
            <img
              src="/img/gallery/29.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(19)">
          <figure>
            <img
              src="/img/gallery/30.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(20)">
          <figure>
            <img
              src="/img/gallery/31.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(21)">
          <figure>
            <img
              src="/img/gallery/32.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(22)">
          <figure>
            <img
              src="/img/gallery/33.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(23)">
          <figure>
            <img
              src="/img/gallery/34.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(24)">
          <figure>
            <img
              src="/img/gallery/35.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(25)">
          <figure>
            <img
              src="/img/gallery/36.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(26)">
          <figure>
            <img
              src="/img/gallery/37.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(27)">
          <figure>
            <img
              src="/img/gallery/38.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(28)">
          <figure>
            <img
              src="/img/gallery/39.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(29)">
          <figure>
            <img
              src="/img/gallery/40.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(30)">
          <figure>
            <img
              src="/img/gallery/41.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(31)">
          <figure>
            <img
              src="/img/gallery/42.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(32)">
          <figure>
            <img
              src="/img/gallery/43.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(33)">
          <figure>
            <img
              src="/img/gallery/44.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(34)">
          <figure>
            <img
              src="/img/gallery/45.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(35)">
          <figure>
            <img
              src="/img/gallery/46.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(36)">
          <figure>
            <img
              src="/img/gallery/47.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(37)">
          <figure>
            <img
              src="/img/gallery/48.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(38)">
          <figure>
            <img
              src="/img/gallery/49.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(39)">
          <figure>
            <img
              src="/img/gallery/50.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
                <mdb-col md="4" @click.native="showLightbox(40)">
          <figure>
            <img
              src="/img/gallery/51.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(41)">
          <figure>
            <img
              src="/img/gallery/52.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(42)">
          <figure>
            <img
              src="/img/gallery/53.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(43)">
          <figure>
            <img
              src="/img/gallery/54.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
        <mdb-col md="4" @click.native="showLightbox(44)">
          <figure>
            <img
              src="/img/gallery/55.png"
              class="img-fluid"
              alt
            />
          </figure>
        </mdb-col>
      </mdb-row>
        <div class="text-center mt-5 pt-3" style="width: 100%;">
          <div class="ar-main-title mb-2 mt-5" style="font-size: 30px;">Video Gallery</div>
      </div>
      <mdb-row class="mt-5 mb-5"> 
        <mdb-col md="12">
            <video preload="auto" width="100%" autoplay="autopĺay" muted loop playsinline>
              <source src="/img/gallery/video/1.mp4" type="video/mp4">
            </video>
        </mdb-col>
      </mdb-row>
      <hr>
      <mdb-row class="mt-5 mb-5"> 
        <mdb-col md="12">
            <video preload="auto" width="100%" autoplay="autopĺay" muted loop playsinline>
              <source src="/img/gallery/video/2.mp4" type="video/mp4">
            </video>
        </mdb-col>
      </mdb-row>
      <hr>
      <mdb-row class="mt-5 mb-5"> 
        <mdb-col md="12">
            <video preload="auto" width="100%" autoplay="autopĺay" muted loop playsinline>
              <source src="/img/gallery/video/3.mp4" type="video/mp4">
            </video>
        </mdb-col>
      </mdb-row>
      <hr>
      <mdb-row class="mt-5 mb-5"> 
        <mdb-col md="12">
            <video preload="auto" width="100%" autoplay="autopĺay" muted loop playsinline>
              <source src="/img/gallery/video/4.mp4" type="video/mp4">
            </video>
        </mdb-col>
      </mdb-row>
      <hr>
      <mdb-row class="mt-5"> 
        <mdb-col md="12">
            <video preload="auto" width="100%" autoplay="autopĺay" muted loop playsinline>
              <source src="/img/gallery/video/5.mp4" type="video/mp4">
            </video>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <mdb-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></mdb-lightbox>
    <Footer/>
    </div>
</template>
<script>
import Header from '../components/Header';
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbLightbox, mdbParallax, mdbMask, mdbCarousel } from "mdbvue";
import Footer from "./Footer";
export default {
    name: "Gallery",
    components: {
        Header,
        Footer,
        mdbContainer, 
        mdbRow, 
        mdbCol, 
        mdbCard,
        mdbLightbox,
        mdbParallax,
        mdbMask,
        mdbCarousel
    },
    data(){
        return {
                imgs: [
                    "/img/gallery/11.png",
                    "/img/gallery/12.png",
                    "/img/gallery/13.png",
                    "/img/gallery/14.png",
                    "/img/gallery/15.png",
                    "/img/gallery/16.png",
                    "/img/gallery/17.png",
                    "/img/gallery/18.png",
                    "/img/gallery/19.png",
                    "/img/gallery/20.png",
                    "/img/gallery/21.png",
                    "/img/gallery/22.png",
                    "/img/gallery/23.png",
                    "/img/gallery/24.png",
                    "/img/gallery/25.png",
                    "/img/gallery/26.png",
                    "/img/gallery/27.png",
                    "/img/gallery/28.png",
                    "/img/gallery/29.png",
                    "/img/gallery/30.png",
                    "/img/gallery/31.png",
                    "/img/gallery/32.png",
                    "/img/gallery/33.png",
                    "/img/gallery/34.png",
                    "/img/gallery/35.png",
                    "/img/gallery/36.png",
                    "/img/gallery/37.png",
                    "/img/gallery/38.png",
                    "/img/gallery/39.png",
                    "/img/gallery/40.png",
                    "/img/gallery/41.png",
                    "/img/gallery/42.png",
                    "/img/gallery/43.png",
                    "/img/gallery/44.png",
                    "/img/gallery/45.png",
                    "/img/gallery/46.png",
                    "/img/gallery/47.png",
                    "/img/gallery/48.png",
                    "/img/gallery/49.png",
                    "/img/gallery/50.png",
                    "/img/gallery/51.png",
                    "/img/gallery/52.png",
                    "/img/gallery/53.png",
                    "/img/gallery/54.png",
                    "/img/gallery/55.png"

                ],
                visible: false,
                index: 0,
                videoCarousel: [
                  {
                    video: true,
                    src: "/img/gallery/video/1.mp4",
                    loop: true,
                    auto: true,
                    muted: true,
                    playsinline: true
                  },
                  {
                    video: true,
                    src: "/img/gallery/video/2.mp4",
                    loop: true,
                    auto: true,
                    muted: true,
                    playsinline: true
                  },
                  {
                    video: true,
                    src: "/img/gallery/video/3.mp4",
                    loop: true,
                    auto: true,
                    muted: true,
                    playsinline: true
                  },
                  {
                    video: true,
                    src: "/img/gallery/video/4.mp4",
                    loop: true,
                    auto: true,
                    muted: true,
                    playsinline: true
                  },
                  {
                    video: true,
                    src: "/img/gallery/video/5.mp4",
                    loop: true,
                    auto: true,
                    muted: true,
                    playsinline: true
                  },
                  {
                    video: true,
                    src: "/img/gallery/video/6.mp4",
                    loop: true,
                    auto: true,
                    muted: true,
                    playsinline: true
                  }
                ]
        };
    },
    methods: {
        showLightbox(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        }
    }
}
</script>
<style>
h4 {
  margin: 0;
}
.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
}
.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
}
.gallery .animation {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 450px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

@media (max-width: 400px) {
  .btn.filter {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}

@media (min-width: 768px) {
  .carousel-multi-item-2 .col-md-3 {
    float: left;
    width: 25%;
    max-width: 100%;
  }
}

.carousel-multi-item-2 .card img {
  border-radius: 2px;
}

figure {
  cursor: pointer;
}
</style>